import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, map, of, switchMap } from 'rxjs';
import * as fromStats from 'src/app/store/stats';
import { PlacementActivityDetails } from 'src/app/shared/models/placement.model';

import { PlacementService } from '../../../shared/services/placement.service';
import * as fromActions from '../actions/activity.actions';

@Injectable()
export class ActivityEffects {
  constructor(
    private actions$: Actions,
    private placementService: PlacementService,
    private store: Store<fromStats.FeatureState>,
  ) {}

  loadActivities$ = createEffect(() => {
    return combineLatest([
      this.actions$.pipe(ofType(fromActions.loadActivities)),
      this.store.select(fromStats.getMetadataState),
    ]).pipe(
      switchMap(([filters, state]) => {
        return this.placementService.getPlacementActivity().pipe(
          map((response) => {
            return response.data.map((activity) => {
              const details: PlacementActivityDetails = {
                ...activity,
                industry: state.industries
                  .getDataOrEmpty()
                  ?.find((it) => it.id === activity.industry_id),
                job_function: state.jobFunctions
                  .getDataOrEmpty()
                  ?.find((it) => it.id === activity.job_function_id),
                company_size: state.companySizes
                  .getDataOrEmpty()
                  ?.find((it) => it.id === activity.company_size_id),
                job_sub_category: state.jobFunctionSubCategories
                  .getDataOrEmpty()
                  ?.find((it) => it.id === activity.job_sub_category_id) ?? {
                  id: activity.job_sub_category_id!,
                  job_function_id: activity.job_function_id!,
                  name: '',
                  description: '',
                },
                payments: [],
              };

              return details;
            });
          }),
        );
      }),
      map((placementActivities) => {
        return fromActions.loadActivitiesSuccess({
          activities: placementActivities,
        });
      }),
      catchError((error) => {
        return of(
          fromActions.loadActivitiesFailure({
            error: error,
          }),
        );
      }),
    );
  });

  approveExchangeRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.approveExchangeRequest),
      switchMap(({ placementId }) => {
        return this.placementService.approveExchangeRequest(placementId).pipe(
          map((it) =>
            fromActions.approveExchangeRequestSuccess({
              placementActivity: it.data,
            }),
          ),
        );
      }),
    );
  });

  declineExchangeRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.declineExchangeRequest),
      switchMap(({ placementId }) => {
        return this.placementService.declineExchangeRequest(placementId).pipe(
          map((it) =>
            fromActions.declineExchangeRequestSuccess({
              placementActivity: it.data,
            }),
          ),
        );
      }),
    );
  });
}
