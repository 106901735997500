import { createAction, props } from '@ngrx/store';
import {
  PlacementActivity,
  PlacementActivityDetails,
  PlacementFilters,
} from 'src/app/shared/models/placement.model';

/**
 *
 * Load activities */
export const loadActivities = createAction('[Activity] Load Activities');

export const loadActivitiesSuccess = createAction(
  '[Activity] Load Activities Success',
  props<{
    activities: PlacementActivityDetails[];
  }>(),
);

export const loadActivitiesFailure = createAction(
  '[Activity] Load Activities Failure',
  props<{ error: any }>(),
);

export const approveExchangeRequest = createAction(
  '[Activity] Approve Exchange Request',
  props<{ placementId: number }>(),
);

export const approveExchangeRequestSuccess = createAction(
  '[Activity] Approve Exchange Request Success',
  props<{ placementActivity: PlacementActivity }>(),
);

export const declineExchangeRequest = createAction(
  '[Activity] Decline Exchange Request',
  props<{ placementId: number }>(),
);

export const declineExchangeRequestSuccess = createAction(
  '[Activity] Decline Exchange Request Success',
  props<{ placementActivity: PlacementActivity }>(),
);

export const selectActivity = createAction(
  '[Activity] Select Activity',
  props<{ id: number }>(),
);

export const setActivityDetails = createAction(
  '[Activity] Set Activity Details',
  props<{ placementActivityDetails?: PlacementActivityDetails }>(),
);
