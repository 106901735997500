<div class="header-container">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a
        class="logo"
        routerLink="/"
      >
        <img
          src="assets/images/logo_fpc_main_dark.png"
          alt=""
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-controls="navbarNav"
        aria-expanded="!isNavbarCollapsed"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <ng-container *ngIf="isAuthenticated">
        <div
          class="collapse navbar-collapse"
          [ngbCollapse]="isNavbarCollapsed"
        >
          <ul class="navbar-nav my-3 my-lg-0">
            <ng-container *ngIf="showStatsLinks">
              <li class="nav-item">
                <a
                  routerLinkActive="active"
                  routerLink="/stats"
                  class="nav-link"
                >
                  Input Stats
                </a>
              </li>

              <li class="nav-item">
                <a
                  routerLinkActive="active"
                  routerLink="/activity"
                  class="nav-link position-relative"
                >
                  <span>Pending Activity</span>
                  <div
                    *ngIf="hasPendingActivities"
                    class="notification-dot"
                  ></div>
                </a>
              </li>

              <li class="nav-item">
                <a
                  routerLinkActive="active"
                  routerLink="/reports"
                  class="nav-link"
                >
                  Reports
                </a>
              </li>

              <li
                *ngIf="showUserManagementScreens"
                class="nav-item"
              >
                <a
                  routerLinkActive="active"
                  routerLink="/user-management"
                  class="nav-link"
                >
                  User Management
                </a>
              </li>

              <li
                *ngIf="showOfficeManagementScreens"
                class="nav-item"
              >
                <a
                  routerLinkActive="active"
                  routerLink="/office-management"
                  class="nav-link"
                >
                  Office Management
                </a>
              </li>
            </ng-container>
          </ul>

          <div class="d-none d-lg-block">
            <div
              role="button"
              class="header-profile-container"
              ngbDropdown
            >
              <div
                class="d-flex"
                ngbDropdownToggle
              >
                <div class="me-2">
                  <div class="name">{{ fullName }}</div>
                  <div class="subtitle">{{ subtitle }}</div>
                </div>

                <img
                  class="profile-img"
                  src="/assets/images/person.svg"
                  width="40"
                  height="40"
                  alt=""
                />

                <div
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasic1"
                >
                  <a
                    class="ms-3 nav-link logout"
                    (click)="onLogout()"
                  >
                    <img
                      src="/assets/svgs/logout.svg"
                      class="bi me-2"
                    />
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="d-block d-lg-none mb-2">
            <a
              class="logout-btn"
              (click)="onLogout()"
            >
              <img src="/assets/svgs/logout.svg" />
              Logout
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </nav>
</div>
