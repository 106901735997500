import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './shared/components/header/header.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { NetworkInterceptor } from './shared/interceptors/network.interceptor';

/**
 * State Management
 */
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { MetaReducer, StoreModule } from '@ngrx/store';

import { CustomSerializer, effects, reducers } from './store/router';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';

/** not used in production */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';

import { GeneralEffects } from './store/stats/effects/general.effects';
import { MetadataEffects } from './store/stats/effects/metadata.effects';
import { MoneyDownSearchEffects } from './store/stats/effects/money-down-search.effects';
import { OtherFeeEffects } from './store/stats/effects/other-fee.effects';
import { PlacementEffects } from './store/stats/effects/placement.effects';
import * as fromStats from './store/stats/reducers';
import * as fromActivity from './store/activity/reducers';
import { ActivityEffects } from './store/activity/effects/activity.effects';
import reportsSlice from './store/reports/reports.slice';
import { ReportEffects } from './store/reports/reports.effects';
import { ToastComponent } from './shared/components/toast/toast.component';
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storeFreeze]
  : [];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    LoadingComponent,
    ToastComponent,
  ],
  imports: [
    NgbToastModule,
    NgbDropdownModule,
    NgbCollapseModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreModule.forFeature(fromStats.statsFeatureKey, fromStats.reducers),
    StoreModule.forFeature(
      fromActivity.activityFeatureKey,
      fromActivity.reducers,
    ),
    StoreModule.forFeature(reportsSlice.name, reportsSlice.reducer),
    EffectsModule.forFeature([
      PlacementEffects,
      MetadataEffects,
      GeneralEffects,
      MoneyDownSearchEffects,
      OtherFeeEffects,
      ActivityEffects,
      ReportEffects,
    ]),
    NgxMaskModule.forRoot(maskConfig),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
